<script lang="ts" setup>
import type { GameModes, PresetPackagePopupData } from "@/types";

defineProps<{
	hideTooltip?: boolean;
}>();

const { t } = useT();
const route = useRoute();
const isGuest = useIsGuest();
const { winnings, entries, tourPoints } = useBalance({
	options: { cached: true, immediate: !isGuest.value, watch: [isGuest] }
});
const { isSweepStakes, handleSwitchGameMode } = useSwitchMode();
const { data: appInitData } = useAppInitData();

const handleChangeTab = async (mode: GameModes) => {
	await handleSwitchGameMode(mode);

	dispatchGAEvent({
		event: "click_button",
		location: "header",
		button_name: isSweepStakes.value ? "switch_promotional" : "switch_tourney"
	});
};
const handleOpenCash = () => {
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>

<template>
	<div
		data-tid="header-balances"
		:class="['balance-tabs', { 'is-sweep-stakes': isSweepStakes, 'hide-popper': Boolean(route.query.game) }]"
	>
		<APopper openDelay="500" arrow :show="!(Boolean(route.query.game) || hideTooltip) && undefined">
			<div :class="['balance-tab', { active: isSweepStakes }]" @click="handleChangeTab('SweepStakes')">
				<div class="sweeps-stakes">
					<MPrizeFund variant="entries" icon="14/cash" :icon-size="16">
						<AText class="text-cixi" variant="topeka" data-tid="entries-coins" :modifiers="['bold', 'ellipsis']">
							<AAnimationNumberCounter :number="entries" />
						</AText>
					</MPrizeFund>
					<AText class="label" variant="ternopil" :modifiers="['medium']">
						<span class="win">{{ t("Win.") }}</span>
						<b data-tid="winnings-coins"><AAnimationNumberCounter :number="Number(winnings)" :decimals="2" /></b>
					</AText>
				</div>
			</div>
			<template #content>
				<div class="popper-flex">
					<div>
						<AText class="title text-cixi" variant="toledo" :modifiers="['bold']" as="div">
							{{ t("Promotional mode is ON") }}
						</AText>
						<AText class="text-cannes" as="p">
							{{ t("Getting serious can be fun too. Win REAL prizes.") }}
						</AText>
					</div>
					<NuxtIcon class="popper-icon" name="20/entries" filled />
				</div>
				<template v-if="entries === 0">
					<AText class="text-cannes"> {{ t("Buy Coins bundle and get Entries for free.") }} </AText>
					<MPromotionSmallItem
						v-if="appInitData?.popupPresetPackages?.length"
						:offer="appInitData?.popupPresetPackages?.[0] as PresetPackagePopupData"
					/>
					<AButton v-else variant="primary" data-tid="header-open-store" @click="handleOpenCash">
						<AText :modifiers="['uppercase']">{{ t("Open Store") }}</AText>
					</AButton>
				</template>
			</template>
		</APopper>
		<APopper openDelay="500" arrow :show="!(Boolean(route.query.game) || hideTooltip) && undefined">
			<div :class="['balance-tab', { active: !isSweepStakes }]" @click="handleChangeTab('TournamentPoints')">
				<MPrizeFund variant="coins" :iconSize="40" img="/nuxt-img/cards/coins.png">
					<AText class="text-coimbatore" variant="topeka" data-tid="points-coins" :modifiers="['bold', 'ellipsis']">
						<AAnimationNumberCounter :number="tourPoints" />
					</AText>
				</MPrizeFund>
			</div>
			<template #content>
				<div class="popper-flex">
					<div>
						<AText class="title text-coimbatore" variant="toledo" :modifiers="['bold']" as="div">
							{{ t("Tournament mode is ON") }}
						</AText>
						<AText class="text-cannes" as="p">
							{{ t("Go a playing spree with ALL these games and have fun!") }}
						</AText>
					</div>
					<NuxtImg class="popper-icon" src="/nuxt-img/races/coins-88.webp" width="88" height="88" alt="coins" />
				</div>
			</template>
		</APopper>
	</div>
</template>

<style lang="scss" scoped>
.balance-tabs {
	display: flex;
	justify-content: center;
	padding: 2px;
	border: 1px solid var(--cairns);
	border-radius: 50px;
	position: relative;
	height: 36px;
	line-height: 1;

	&::before {
		content: "";
		position: absolute;
		top: 2px;
		left: 2px;
		width: calc(50% - 4px);
		height: calc(100% - 4px);
		background-color: var(--cairns);
		border-radius: inherit;
		border: 1px solid var(--cairns);
		z-index: 0;
		transition: transform 0.2s ease;
		transform: translateX(calc(100% + 4px));
	}

	&.is-sweep-stakes::before {
		transform: translateX(0);
	}

	:deep(.inline-block) > div:first-child {
		height: 100%;
	}

	:deep(.popper) {
		--popper-theme-background-color: var(--cairns);
		--popper-theme-background-color-hover: var(--cairns);
		--popper-theme-border-radius: 10px;
		--popper-theme-box-shadow: 0 4px 4px var(--chuncheon);
		--popper-theme-padding: 15px;
		z-index: 8;
		min-width: 328px;
		line-height: 21px;

		.popper-icon {
			font-size: 44px;
			width: 44px;
			height: auto;
			margin-left: gutter(1.875);
		}
		p {
			margin-top: gutter(1.25);
		}
		.popper-flex {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: nowrap;
		}
		.banner,
		button {
			margin-top: gutter(1.5);
		}
	}
}

.balance-tab {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 115px;
	height: inherit;
	padding: gutter(0.25) 0;
	transition: opacity 0.2s ease;

	@include media-breakpoint-down(md) {
		width: 92px;

		&:deep(span):not(.nuxt-icon) {
			font-size: 10px;
		}
	}

	@media (max-width: 460px) {
		width: 83px;
	}

	&:not(.active) {
		opacity: 0.4;
		cursor: pointer;
	}

	&.active {
		pointer-events: none;

		&:deep(span) {
			font-weight: 700;
		}
	}

	span.win {
		font-weight: 500;
		color: var(--calgary);
	}

	.label {
		display: inline-flex;
		gap: gutter(0.4);
		line-height: 10px;
		color: var(--cannes);
	}

	.sweeps-stakes {
		display: flex;
		flex-direction: column;
	}

	:deep(.prize.coins) {
		img {
			width: 16px;
			height: 16px;
		}
	}
}
</style>
